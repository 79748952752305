

.App {
  margin: auto;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;  
  max-width: 890px;
  color: #585764;
}

.Social-link {
  margin: 0px 10px 0px 10px;
}


.Header {
  padding-top: 75px;
  padding-bottom: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Header-socials {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Body {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: 'Raleway';
}

.Col-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 390px;
}

.Col-left h1 {
 font-family: 'Josefin Sans'; 
 font-size: 64px;
 margin-bottom: 0px;
}


.Col-right {
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  width: 500px;
}



.Subtitle {
  font-family: 'Josefin Sans';
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 64px;
  font-weight: bold;
  text-decoration: none;
  color: #585764;
}

p {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}


.Row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Content-column {
  text-align: center;
  font-family: 'Raleway';
  font-weight: 300;
  padding-bottom: 200px;
  padding-top: 100px;
}


a {
  text-decoration: none;
  color: #585764;
}



.Navbar {
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Navbar .Subtitle {
  margin-bottom: 0px;
  margin-top: 5px;
  font-size: 28px;
}

.Body-image {
  width: 390px;
  height: 140px;
}

.wave {
  width: 90px;
  height: 90px;
}


@media (max-width: 1000px) {
  .App {
    margin: 0px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
  .wave {
    width: 70px;
    height: 70px;
  }
  .Body-image {
    width: 330px;
    height: 110px;
  }

  .Body {
    justify-content: center;
    align-items: center;
  }
  
  .Col-left {
    width: 330px;
  }
  .Col-right {
    padding-left: 30px;
    width: 350px;
  }
  .Col-left h1 {
    font-size: 52px;
  }
  .Col-right h3 {
    font-size: 24px;
    line-height: 48px;
  }
  .Col-right p {
    font-size: 12px;
  }
  .Navbar .Subtitle {
    font-size: 18px;
  }

}

@media only screen and (max-width: 600px) {
  .Body {
    flex-direction: column;
    width: 100%;
  }
  
  .Header {
    padding-top: 50px;
    margin: 0px;
  }
  
  .Col-left {
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
  }
  .Col-right {
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
  }
  .Navbar {
    margin-bottom: 20px;
  }
  .App {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Body-image {
    display: none;
  }
  .Col-left h1 {
    font-size: 52px;
  }
  .Col-right h3 {
    font-size: 24px;
    line-height: 48px;
  }
  .Col-right p {
    font-size: 12px;
  }
  .Navbar .Subtitle {
    font-size: 18px;
  }
}