@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

html {
  width: 100%;
  margin: 0px;  
}

body {
  margin: 0px;
  width: 100%;
  background-color: #fffcfc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
